import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from "../image/Logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faBars } from "@fortawesome/free-solid-svg-icons";
import "./navbar.css"
import $ from "jquery";
import { useState } from 'react';


export default function Navbar() {
  const [isSectionSelected, setIsSectionSelected] = useState(false);

  const handleSectionClick = () => {
    // قم بتحديث الحالة عند اختيار القسم
    setIsSectionSelected(true);
  }



  $(".navbar ul li").on("click", function(){
    $(window).scrollTop(0);
    $(".navbar-collapse").removeClass("show")
  })

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark position-fixed top-0 w-100 mb-5 p-2">
        <div className="container-fluid">
          <Link data-aos="fade-right" className="navbar-brand" to="/">
            <img src={logo} alt="سيرفر عرب ليونز"/>
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
            <span className="navbar-toggler-icon">
              <FontAwesomeIcon icon={faBars} />
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={`navbar-nav me-auto mb-2 mb-lg-0 ${isSectionSelected ? 'd-none d-lg-block' : ''}`}>
                <li data-aos="fade-down" data-aos-duration="800" className="nav-item ">
                  <NavLink data-text="الرئيسية" className="nav-link" aria-current="page" to="/" >
                    الرئيسية
                  </NavLink>
                </li>
                <li data-aos="fade-down" data-aos-duration="900" className="nav-item" >
                  <NavLink data-text="الاسعار" className="nav-link" aria-current="page" to="price" >
                    الاسعار
                  </NavLink>
                </li>
                <li data-aos="fade-down" data-aos-duration="1000" className="nav-item" >
                  <NavLink data-text="تطبيقاتنا" className="nav-link" aria-current="page" to="app" >
                    تطبيقاتنا
                  </NavLink>
                </li>
                <li data-aos="fade-down" data-aos-duration="1000" className="nav-item" >
                  <NavLink data-text="الدعم" className="nav-link" aria-current="page" to="TechnicalSupport" >
                    الدعم
                  </NavLink>
                </li>
                <li data-aos="fade-down" data-aos-duration="1000" className="nav-item" >
                  <NavLink data-text="البحث عن المحتوي" className="nav-link" aria-current="page" to="search" >
                    البحث عن المحتوي
                  </NavLink>
                </li>
                <li data-aos="fade-down" data-aos-duration="1000" className="nav-item" >
                  <NavLink data-text="تقييم العملاء" className="nav-link" aria-current="page" to="review" >
                    تقييم العملاء
                  </NavLink>
                </li>
              </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
