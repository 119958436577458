import React from 'react'
// import data from "./AllDatamadan.json"
import { useEffect, useState } from 'react';
import Movie from './Movie';
import TvShow from './TvShow';
import "./AllData.css"

export default function AllData() {

  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.mrmazika.com/gmovies.php');
        console.log(response);
        const data = await response.json();
        setAllData(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };

    fetchData();
  }, []);


  const handleSearch = (query) => {
    const filteredData = allData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredData.slice(0,50));
  };


  return (
    <div className='pearent overflow-x-hidden'>
    <div className='mt-5 pt-5 w-100 mx-auto d-flex justify-content-center'>
      <input className='w-75 m-auto in-search p-3 mt-5'
        type="text"
        placeholder="ابحث باسم المحتوي فقط بدون كتابة فيلم او مسلسل"
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          handleSearch(e.target.value);
        }}
        
      />
      </div>

      <>
      {searchTerm ? <div>
      <div className='row mt-2 px-5 justify-content-center'>
      {searchResults.map((search)=>{
        return(<div className='col-6 col-sm-4 col-lg-2 mt-4 ramadan rounded'>
          <div className='item'>
            <div className='box position-relative overflow-hidden'>
              <div className='position-relative'>
                <div className='position-absolute type'>{search.stream_type ? search.stream_type : "Tv Show"}</div>
                <img className='rounded' src={search.cover ? search.cover : search.stream_icon}/>
              </div>
              <div className='d-flex align-items-center overflow-hidden'>
                <p className='position-absolute bottom-0 text-center mb-0 p-1 fs-6'>
                {search.plot}
              </p>
              </div>
            </div>
            <h5 className='mt-3 text-text-white-50'>{search.Genre} {search.name}</h5>
            <h6 className='mt-3 category_name'>فولدر {search.category_name}</h6>
          </div>
        </div>)
      })}  
      </div>
    </div> : 
    <div className='row mt-3 pt-3 px-5'>
      <ul className="nav nav-tabs d-flex justify-content-center border-0" id="myTab"  role="tablist" >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active text-white"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              احدث الافلام المضافة
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link text-white"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              احدث المسلسلات المضافة
            </button>
          </li>
        </ul>

        <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
          <Movie/>
        </div>
        <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0" >
          <TvShow/>
        </div>
      </div>
      </div>}
      </>

    </div>
      
  )
}
