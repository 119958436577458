import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Price from './Components/Price/Price';
import TechnicalSupport from './Components/TechnicalSupport/TechnicalSupport';
import Aos from 'aos';
import { useEffect } from 'react';
import DownApp from './Components/DownApp/DownApp';
import WhatsApp from './Components/WhatsApp/WhatsApp';
import $ from "jquery";
import Footer from './Components/Footer/Footer';
import { Helmet } from 'react-helmet';
import Error from './Components/Error/Error';
import AllData from './Components/ShowData/AllData';
import Review from './Components/Review/Review';


var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/654d31f4958be55aeaae2584/1heqom9dp';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();



function App() {

  function scrollUp() {
    $(window).scrollTop('0')
  }

  $(".navbar .nav-item").click(scrollUp())

  $(window).scroll(function () {
    if ($(window).scrollTop() > 80) {
      $("nav").css("backgroundColor", "#131722b8").css("borderBottom", "1px solid #fe6100");
    } else {
      $("nav").css("backgroundColor", "transparent").css("borderBottom", "transparent");
    }
  });



  useEffect(() => {
    
    Aos.init();
  }, []);

  return (
    <div className='overflow-y-hidden'>
    <Helmet>
          <title>الرئسية | سيرفر عرب ليونز</title>
          <link rel="canonical" href="https://lionz.tv/" />
          <meta name="description" content="سيرفر ليونز تي في أقوي سيرفر لمشاهدة المباريات والمحتوي الترفيهي في الشرق الأوسط, يحتوي علي أكثر من 200 تيرا افلام ومسلسلات وقنوات ممتازة بجودات مختلفة
      نعمل في ليونز تي في علي توفير أفضل تجربة للمستخدم, فنقوم بتقسيم..."></meta>


        </Helmet>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/price" element={<Price />} />
        <Route path="/TechnicalSupport" element={<TechnicalSupport />} />
        <Route path="/app" element={<DownApp />} />
        <Route path="/search" element={<AllData />} />
        <Route path="/review" element={<Review />} />
        <Route path="/*" element={<Error />} />
      </Routes>
      <WhatsApp/>
      <Footer/>
      
    </div>
  );
}

export default App;
