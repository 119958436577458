import React, { useState } from 'react'
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faSatelliteDish,faFilm,faTv} from "@fortawesome/free-solid-svg-icons";
import "./countDon.css"

export default function CountData() {

  const [counterOn, setCounterOn] = useState(false);


  return (
    <>
      <div className="data counter-data position-relative">
        <div className="container-failed media position-relative">
        <div className="wave-top">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#0e192f"
              fill-opacity="1"
              d="M0,256L80,218.7C160,181,320,107,480,69.3C640,32,800,32,960,53.3C1120,75,1280,117,1360,138.7L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
            ></path>
          </svg>
        </div>
          <div className="container-media">
            <ScrollTrigger className="h-100"
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <div className="item py-5 w-75 m-auto text-center container-media-item row">
                <div className="live itemas-icone col-sm-4"data-aos="fade-down"data-aos-duration="800"data-aos-delay="200">
                  <FontAwesomeIcon icon={faSatelliteDish} />
                  <h3 className="mt-2">قنوات</h3>
                  <h4 data-goal="9000">
                    {counterOn && (
                      <CountUp start={0} end={9000} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                </div>
                <div className="movie col-sm-4" data-aos="fade-down" data-aos-duration="800" data-aos-delay="400">
                  <FontAwesomeIcon icon={faFilm} />
                  <h3 className="mt-2">افلام</h3>
                  <h4 data-goal="29000">
                    {counterOn && (
                      <CountUp start={0} end={29000} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                </div>
                <div className="series col-sm-4" data-aos="fade-down" data-aos-duration="800" data-aos-delay="600" >
                  <FontAwesomeIcon icon={faTv} />
                  <h3 className="mt-2">مسلسلات</h3>
                  <h4 data-goal="9000">
                    {counterOn && (
                      <CountUp start={0} end={9000} duration={3} delay={0} />
                    )}
                    +
                  </h4>
                </div>
              </div>
            </ScrollTrigger>
          </div>
          <div className="wave position-absolute bottom-0 w-100 start-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#0e192f"
                fill-opacity="1"
                d="M0,192L80,213.3C160,235,320,277,480,282.7C640,288,800,256,960,218.7C1120,181,1280,139,1360,117.3L1440,96L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
