import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import { FaDownload } from 'react-icons/fa';
import "./app.css"

export default function App() {

  return (
    <>
      <div className="app">
        <div className="text-center" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200" >
          <h2 className="fs-1">تطبيقاتنا</h2>
          <p className="text-white-50 fs-6">
            لدينا اكثر من تطبيق لمختلف الاجهزة
          </p>
        </div>
        <div className="container pt-5">
          <div className="row py-2 bs-apps">
            <div className="text-center pt-lg-5 support-text">
              <h2
                className="ps-3 w-50" data-aos="fade-left"  data-aos-duration="800" data-aos-delay="200" >
                مع سيرفر عرب ليونز استمتع باكبر باقة من القنوات العالمية
                والعربية
              </h2>
              <p className="ps-5 text-text-white-50" data-aos="fade-left" data-aos-duration="800" data-aos-delay="300" >
                سيرفر عرب ليونز سيرفراتنا تدعم العمل على جميع الاجهزة
              </p>

              <ul className="ps-lg-5 mb-3">
                <li className="ps-lg-5 mt-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="500" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الهواتف والاجهزة الاندرويد
                </li>
                <li
                  className="ps-lg-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الهواتف والاجهزة الـApple
                </li>
                <li className="ps-lg-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="700" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الاجهزة بنظام ويندوز
                </li>
                <li
                  className="ps-lg-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="800" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم جميع الشاشات الذكيه
                </li>

              </ul>
              <div className="butom-app mt-5">
                <Link className="btn nav-page-downApp" to="app" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400">
                  تحميل التطبيق <FaDownload className=' ms-2' />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
