import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import line from "./Gold.json";

export default function EgyptGold() {
return <>
    <div className="container">
      <div className="row mt-5 m-auto">
      {line.egypt.map((data , key)=>{
        return(
          <div key={key} className="col-md-6 col-lg-4 year price-box">
          <div className="item text-black p-3" data-aos="zoom-in" data-aos-duration="800" data-aos-delay="200" >
            <h2 className="text-center" data-aos="fade-down" data-aos-duration="800" data-aos-delay="300" >
              {data.box.time}
            </h2>
            <div className="box-price mt-3" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400" >
              <h4>{data.box.price}</h4>
              <h5 className="ms-4">{data.box.currency}</h5>
            </div>
            <ul>
              {line.features.map((lin , key)=>{
                return(
                  <li key={key}><span className="me-2">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                {lin.line}</li>
                )
              })}
              <li key={key}><span className="me-2">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                التشغيل داخل مصر فقط</li>
            </ul>
            <a href="https://qrco.de/lionztv" target="_blank" >
              <button className="btn">اشترك الان Gold</button>
            </a>
          </div>
        </div>
        )
      })}
      </div>
    </div>
  </>
}
