import React from "react";
import "./price.css";
import SaudiaVip from "./VIP/SaudiaVip";
import EgyptVip from "./VIP/EgyptVip";
import AeVip from "./VIP/EmiratesVip";
import SaudiaGold from "./Gold/SaudiaGold";
import AllVip from "./VIP/AllVip";
import EgyptGold from "./Gold/EgyptGold";
import EmiratesGold from "./Gold/EmiratesGold";
import AllGold from "./Gold/AllGold";
import { Helmet } from "react-helmet";

export default function Price() {
  return (
    <>
      <div className="price p-lg-5 d-block">
        <Helmet>
          <title>اشتراك الان | سيرفر عرب ليونز</title>
          <link rel="canonical" href="https://lionz.tv/#/price" />
          <meta name="description" content="اشترك الان في سيرفر عرب ليونز وستمتع بمشاهدة كل جديد"></meta>
        </Helmet>
        <ul className="nav nav-tabs d-flex justify-content-center border-0" id="myTab"  role="tablist" >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              Lionz TV VIP
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              Lionz TV Gold
            </button>
          </li>
        </ul>
        <div className="tab-content tabContent tab-price" id="myTabContent">
          <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
            <h2 className="text-center mt-5" data-aos="fade-down"  data-aos-duration="800" data-aos-delay="200" >  اطلب الاشتراك الان VIP </h2>
            <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <div className="nav-link active" id="sau-vip" data-bs-toggle="pill" data-bs-target="#saudia-vip" type="button" role="tab" aria-controls="saudia-vip" aria-selected="true">السعودية</div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link" id="egy-vip" data-bs-toggle="pill" data-bs-target="#egypt-vip" type="button" role="tab" aria-controls="egypt-vip" aria-selected="false">مصر</div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link" id="un-vip" data-bs-toggle="pill" data-bs-target="#Ae-vip" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">الامارات</div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link" id="all-vip" data-bs-toggle="pill" data-bs-target="#all-wo-vip" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">جميع دول العالم</div>
              </li>
            </ul>
            <div className="tab-content tabContent" id="pills-tabContent">
              <div className="tab-pane fade show active" id="saudia-vip" role="tabpanel" aria-labelledby="sau-vip" tabindex="0"><SaudiaVip/></div>
              <div className="tab-pane fade" id="egypt-vip" role="tabpanel" aria-labelledby="egy-vip" tabindex="0"><EgyptVip/></div>
              <div className="tab-pane fade" id="Ae-vip" role="tabpanel" aria-labelledby="un-vip" tabindex="0"><AeVip/></div>
              <div className="tab-pane fade" id="all-wo-vip" role="tabpanel" aria-labelledby="all-vip" tabindex="0"><AllVip/></div>
            </div>
          </div>
          <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0" >
            <h2 className="text-center mt-5" data-aos="fade-down"  data-aos-duration="800" data-aos-delay="200" >  اطلب الاشتراك الان Gold </h2>
            <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <div className="nav-link active" id="sau-gold" data-bs-toggle="pill" data-bs-target="#saudia-gold" type="button" role="tab" aria-controls="saudia-gold" aria-selected="true">السعودية</div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link" id="egy-gold" data-bs-toggle="pill" data-bs-target="#egypt-gold" type="button" role="tab" aria-controls="egypt-gold" aria-selected="false">مصر</div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link" id="un-gold" data-bs-toggle="pill" data-bs-target="#Ae-gold" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">الامارات</div>
              </li>
              <li className="nav-item" role="presentation">
                <div className="nav-link" id="all-gold" data-bs-toggle="pill" data-bs-target="#all-wo-gold" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">جميع دول العالم</div>
              </li>
            </ul>
            <div className="tab-content tabContent" id="pills-tabContent">
              <div className="tab-pane fade show active" id="saudia-gold" role="tabpanel" aria-labelledby="sau-gold" tabindex="0"><SaudiaGold/></div>
              <div className="tab-pane fade" id="egypt-gold" role="tabpanel" aria-labelledby="egy-gold" tabindex="0"><EgyptGold/></div>
              <div className="tab-pane fade" id="Ae-gold" role="tabpanel" aria-labelledby="un-gold" tabindex="0"><EmiratesGold/></div>
              <div className="tab-pane fade" id="all-wo-gold" role="tabpanel" aria-labelledby="all-gold" tabindex="0"><AllGold/></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
