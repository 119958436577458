import React from 'react'
import './footer.css'

export default function Footer() {
  return <>
    <div className='footer p-3'>
      <h5 className='text-center ms-lg-5'>جميع الحقوق محفوظة لسيرفر عرب ليونز</h5>
      <div>
        <a className='' href='https://fb.com/elmasry9600' target='_blank'>Coding By: Elmasry</a>
      </div>
    </div>
  </>
}
