import React from "react";
import Slide from "../Slide/Slide";
import "./home.css";
import CountData from "../CountData/CountData";
import Suppoer from "../Support/Suppoer";
import Price from "../Price/Price";
import ChanalSlide from "../ChanalSlide/ChanalSlide";
import App from "../App/App";
import TechnicalSupport from "../TechnicalSupport/TechnicalSupport";
import { Helmet } from "react-helmet";



export default function Home() {


  return (
    <>
    
      <Slide />
      <ChanalSlide />
      <Suppoer />
      <CountData />
      <Price />
      <App/>
      <TechnicalSupport/>
      <Helmet>
            <title>سيرفر عرب ليونز | Lionz TV</title>
            <link rel="canonical" href="http://localhost:3000/#/app" />
            <meta name="description" content="سيرفر ليونز تي في أقوي سيرفر لمشاهدة المباريات والمحتوي الترفيهي في الشرق الأوسط, يحتوي علي أكثر من 200 تيرا افلام ومسلسلات وقنوات ممتازة بجودات مختلفة
        نعمل في ليونز تي في علي توفير أفضل تجربة للمستخدم, فنقوم بتقسيم..."></meta>
          </Helmet>
    </>
  );
}
