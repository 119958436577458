import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";


import "./Slide.css";

// import "./styles.css";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { Link } from "react-router-dom";

export default function Slide() {
  return (
    <>
      <Swiper 
          spaceBetween={30} 
          effect={"fade"} 
          navigation={true} 
          pagination={{
          clickable: true,
          slidesPerView: 'auto'
        }}
        autoplay={{ delay: 8000 }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper position-relative overflow-y-hidden"
      >
        <SwiperSlide
          className="SwiperSlide" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="500" >
          <div className="w-100 h-100 bg-slide bg1">
            <div className="w-50 items">
              <div className="box-data">
                <h1 data-aos="fade-left" data-aos-duration="1200"  data-aos-delay="500" >
                  <span data-aos="fade-up" data-aos-duration="1500">
                    اشترك الان
                  </span>{" "}
                  
                </h1>
                <p data-aos="fade-left"  data-aos-duration="1200" data-aos-delay="800" >
                  {" "}
                  السيرفر يحتوي علي باقات عائلية وباقات مخصصة للاطفال
                </p>
                <a href="https://qrco.de/lionztv" target="_blank" data-aos="fade-left" data-aos-duration="1200" data-aos-delay="900" >
                  <button className="btn text-white">اشتراك الان</button>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <div className="w-100 h-100 bg-slide bg2">
            <div className="w-50 items">
              <div className="box-data">
                <h1>
                  <span>استمتع </span> بجميع القنوات الرياضية
                </h1>
                <p>
                  باقة من القنوات العالميه والاجنبيه لمتابعه كافه الاحداث الرياضيه والعالميه كل واحده كده فى سطر
                </p>
                <a href="https://qrco.de/lionztv" target="_blank">
                  <button className="btn text-white">اشتراك الان</button>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <div className="w-100 h-100 bg-slide bg3">
            <div className="w-50 items">
              <div className="box-data">
                <h1>
                  <span>محتوي</span> خاص للعائلات
                </h1>
                <p>
                  محتوي عائلي بالكامل ومفلتر ويصلح لجميع افراد العائلة علي سيرفر عرب ليونز
                </p>
                <a href="https://qrco.de/lionztv" target="_blank">
                  <button className="btn text-white">اشتراك الان</button>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <div className="w-100 h-100 bg-slide bg4">
            <div className="w-50 items">
              <div className="box-data">
                <h1>
                  <span>السينما</span> في بيتك 4K
                </h1>
                <p>
                  شاهد الافلام بأفضل تقنيه فى العالم واعلي جودة . <br></br> وصوت محيطي 5.1 وجوده لن تراها فى اي مكان أخر.
                </p>
                <a href="https://qrco.de/lionztv" target="_blank">
                  <button className="btn text-white">اشتراك الان</button>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <div className="w-100 h-100 bg-slide bg6">
            <div className="w-50 items">
              <div className="box-data">
                <h1>
                  <span>مسلسلاتك عندنا</span> في عرب ليونز
                </h1>
                <p>
                  باقه من المسلسلات الترفيهيه ( العربيه والاجنبيه وكافه دول العالم ) <br></br> مسلسلات بجودات مختلفه وحسب الطلب ايضا
                </p>
                <a href="https://qrco.de/lionztv" target="_blank">
                  <button className="btn text-white">اشتراك الان</button>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
