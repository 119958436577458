import React from 'react';
import "bootstrap/dist/css/bootstrap.rtl.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import "aos/dist/aos.js";
import "aos/dist/aos.css";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { hydrate, render } from "react-dom";

const APP = (<HashRouter>
    <App />
  </HashRouter>);


const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


