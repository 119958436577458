import React from 'react'

export default function Review() {
  return <>
    <div className='mt-5 pt-5 review'>
        <div className='mt-5'>
            <h1 className='text-center m-5'>تقييم العملاء</h1>
            <div className='d-flex justify-content-center align-items-center'>
            <iframe className='mb-5' width="50%" height="400" src="https://www.youtube.com/embed/HjisZSMUjR0?si=B2FwZF-Y04yttFms" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        </div>

    </div>
  </>
}
