import React from 'react'
import beinSport from "../image/Bein_sport_logo.png";
import ad from "../image/ad.gif";
import osn from "../image/osn.png";
import ssc from "../image/ssc.png";
import "./ChanalSlide.css"

export default function ChanalSlide() {
  return (
    <>
      <div className="count-items">
        <div className="items d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ps-5 pe-5 channel row">
            <div className="bein col-lg-3 col-sm-6">
              <img alt='Bein Sport' data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" className="w-75 col-lg-3 col-sm-6"
                src={beinSport} />
            </div>
            <div className="ad col-lg-3 col-sm-6">
              <img alt='AD Sports' data-aos="fade-up" data-aos-duration="800" data-aos-delay="400" className="w-75 col-lg-3 col-sm-6" src={ad} />
            </div>
            <div className="ssc col-lg-3 col-sm-6">
              <img alt='SSC Sports' data-aos="fade-up" data-aos-duration="800" data-aos-delay="600" className="w-75" src={ssc} />
            </div>
            <div className="osn col-lg-3 col-sm-6">
              <img alt='OSN' data-aos="fade-up" data-aos-duration="800" data-aos-delay="800" className="w-75" src={osn} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
