import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck } from "@fortawesome/free-solid-svg-icons";
import "./support.css"

export default function Suppoer() {
  return (
    <>
      <div className="support py-5">
        <div className="container-failed overflow-x-hidden">
          <div className="row pt-5">
            <div className="col-md-6 text-center p-4 support-text">
              <h2
                className="ps-3"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                مع سيرفر عرب ليونز استمتع باكبر باقة من القنوات العالمية
                والعربية
              </h2>
              <p
                className="ps-5"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                نحن نقدم خدمة البث التلفزيوني المباشر على جميع الأقمار و من كل
                أنحاء العالم. بالإشتراك معنا ستتمكن من مشاهدة القنوات
                التلفزيونية والأفلام والبرامج والقنوات الرياضية والوثائقية و
                الإخبارية من جميع أنحاء العالم و على جميع الأجهزة الخاصة بك.
              </p>

              <ul className="ps-lg-5">
              <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="800"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  خدمة ما بعد البيع
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="700"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  متوفر اكثر من وسيلة للتواصل مع الدعم الفني 24/7
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="900"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  متوفر خدمة الطلبات المحتوي 3 مرات في الاسبوع
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="1000"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  لدينا قنوات اكثر من +9000 قناة ونحن في تطور مستمر لزيادة
                  العدد.
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  data-aos-delay="1200"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  مكتبه افلام منوعه تحتوي على 29000 فيلم  قابلة للزيادة.
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  data-aos-delay="1200"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  مكتبه مسلسلات منوعه تحتوي على 9000 مسلسل قابله للزياده.
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="800"
                  data-aos-delay="1100"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  بث مباشر للرياضة والبرامج التلفزيونية و افلام.
                </li>
                <li
                  className="ps-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  data-aos-delay="1300"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  محتوي السيرفر متاح باكثر من جودة.
                </li>
              </ul>
            </div>
            <div
              className="col-md-6 mt-lg-5 img-support overflow-x-hidden"
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="500"
            ></div>
          </div>
        </div>
        
      </div>
    </>
  );
}
