import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import telegram from "../image/Telegram_logo.svg.png"
import "./WhatsApp.css"

export default function WhatsApp() {
  return <>
  <div className='telegram'>
      <a href='https://t.me/Lionzsales' target='_blank'>
        <img width='60' src={telegram}/>
      </a>
    </div>
    
    </>

//   <div className='WhatsApp'>
//   <FloatingWhatsApp
//     phoneNumber="201553262717"
//     accountName="LionzTv"
//     allowEsc
//     allowClickAway
//     notification
//     notificationSound
//     statusMessage='يتم الرد خلال دقائق'
//     placeholder="جاري الكتابة"
//     chatMessage="مرحبا بك في سيرفر عرب ليونز 🤝
//       كيف يمكنني مساعدتك ؟"
//     avatar='https://i.imgur.com/xZMBPi9.png'
//   />
// </div>

    
  
}
