import React, { useEffect, useState } from 'react'
import "./AllData.css"


export default function Movie() {

  const [moviesData, setmoviesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.mrmazika.com/m50.php');
        console.log(response);
        const data = await response.json();
        setmoviesData(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);



  return (
    <div>
      <div className='row mt-2 justify-content-center'>
        {moviesData.length === 0 ?
        <div className='vh-100 d-flex justify-content-center align-items-center'>
            <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> : moviesData.map((movie , key)=>{
            return(<div key={key} className='col-6 col-sm-4 col-lg-2 mt-4 ramadan rounded'>
              <div className='item'>
                <div className='box position-relative overflow-hidden'>
                  <div className='position-relative'>
                    <div className='position-absolute type'>{movie.stream_type ? movie.stream_type : "Tv Show"}</div>
                    <img className='rounded' src={movie.cover ? movie.cover : movie.stream_icon}/>
                  </div>
                  <div className='d-flex align-items-center overflow-hidden'>
                    <p className='position-absolute bottom-0 text-center mb-0 p-1 fs-6'>
                    {movie.plot}
                  </p>
                  </div>
                </div>
                <h5 className='mt-3 text-text-white-50'>{movie.name}</h5>
                <h6 className='mt-3 category_name'>فولدر {movie.category_name}</h6>
              </div>
            </div>)
          })}
      </div>
    </div>
  )
}
