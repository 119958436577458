import React from 'react'
import error404  from "../image/404.Error.png"

export default function Error() {
  return <>
    <div className='error mt-lg-5 w-100 vh-100 d-flex justify-content-center align-items-center'>
      <div className='w-50 mt-lg-5 mb-lg-5'><img className='w-100' src={error404}/></div>
    </div>
  </>
}
