import React , {useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sumsung from "../image/sumsung-tv.png"
import { faCheck, faDownload } from "@fortawesome/free-solid-svg-icons";
import links from "./links.json"
import "./DownApp.css";
import $ from "jquery";
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';

export default function DownApp() {


 
  

    

  useEffect(()=>{
    $(window).scrollTop(600)
  },[])



  return (
    <div>
    <Helmet>
      <title>تطبيقاتنا | سيرفر عرب ليونز</title>
      <link rel="canonical" href="https://lionz.tv/#/app" />
      <meta name="description" content=".يمكنك تحميل التطبيقات الخاصة بسيرفر عرب ليونز والاستمتاع بمزاية بلا حدود"></meta>
    </Helmet>
      <div className="app Down-app">
        <div className="text-center" data-aos="fade-down" data-aos-duration="800" data-aos-delay="200">
          <h2 className="fs-1">تطبيقاتنا</h2>
          <p className="text-white-50 fs-6">
            لدينا اكثر من تطبيق لمختلف الاجهزة
          </p>
        </div>
        <div className="container pt-5">
          <div className="row py-2 bs-apps">
            <div className="w-50 text-center pt-lg-5 support-text">
              <h2 className="ps-3" data-aos="fade-left" data-aos-duration="800" data-aos-delay="200" >
                مع سيرفر عرب ليونز استمتع باكبر باقة من القنوات العالمية
                والعربية
              </h2>
              <p className="ps-5 text-text-white-50" data-aos="fade-left" data-aos-duration="800" data-aos-delay="300" >
                سيرفر عرب ليونز سيرفراتنا تدعم العمل على جميع الاجهزة
              </p>
              <ul className="ps-lg-5 mb-3">
                <li className="ps-lg-5 mt-5" data-aos="fade-left"  data-aos-duration="800" data-aos-delay="500" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الهواتف والاجهزة الاندرويد
                </li>
                <li className="ps-lg-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الهواتف والاجهزة الـApple
                </li>
                <li className="ps-lg-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="700" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الاجهزة بنظام ويندوز
                </li>
                <li className="ps-lg-5" data-aos="fade-left" data-aos-duration="800" data-aos-delay="800" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الشاشات الـSumsung
                </li>
                <li className="ps-lg-5"  data-aos="fade-left" data-aos-duration="800" data-aos-delay="900" >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  السيرفر يدعم الشاشات الـLg
                </li>
              </ul>{" "}
              <img />{" "}
            </div>
          </div>
          <div className="container">
          <div className='content'>
            <h2>تحميل تطبيقات الـAndroid</h2>
            <p className='text-white-50 ms-3'>استكشف كل اصدار بنفسك</p>
          </div>
            <div className='d-flex justify-content-center flex-wrap w-100'>
            {links.apk.map((link , key)=>{
              return(
                <div key={key} className='text-center mt-3 m-auto' id='links-apk'>
                <Link to={link.link} target="_blank">
                  <div className='bottom-down'>{link.title} <FontAwesomeIcon className='fa-solid fa-triangle-exclamation fa-fade ms-2' icon={faDownload} /></div>
                </Link>
              </div>
              )
            })}
              
            </div>
          </div>
          <div className="container mt-5">
          <div className='content'>
            <h2>تحميل تطبيقات iOS</h2>
            <p className='text-white-50 ms-3'>استكشف كل اصدار بنفسك</p>
          </div>
            <div className='d-flex justify-content-center flex-wrap w-100'>
            {links.ios.map((link , key)=>{
              return(
                <div key={key} className='text-center mt-3 m-auto'>
                <Link to={link.link} target="_blank">
                  <div className='bottom-down'>{link.title} <FontAwesomeIcon className='fa-solid fa-triangle-exclamation fa-fade ms-2' icon={faDownload} /></div>
                </Link>
              </div>
              )
            })}
              
            </div>
          </div>
          <div className="container mt-5">
          <div className='content'>
            <h2>تحميل تطبيقات Windows</h2>
            <p className='text-white-50 ms-3'>استكشف كل اصدار بنفسك</p>
          </div>
            <div className='d-flex justify-content-center flex-wrap w-100'>
            {links.Windows.map((link , key)=>{
              return(
                <div key={key} className='text-center mt-3 m-auto'>
                <Link to={link.link} target="_blank">
                  <div className='bottom-down'>{link.title} <FontAwesomeIcon className='fa-solid fa-triangle-exclamation fa-fade ms-2' icon={faDownload} /></div>
                </Link>
              </div>
              )
            })}
              
            </div>
          </div>
          <div className="container mt-5">
                <div className="row m-auto smart-tv-list">
                  <div className="col-md-6 p-2">
                    <h3 className='mt-5'>لتحميل تطبيقنا الخاص علي الشاشات السمارت LionzTv</h3>
                    <ul>
                      <li> افتح المتجر الخاص بالشاشة</li>
                      <li> ابحث بكلمة Lionz</li>
                      <li> قم بتثبت التطبيق</li>
                      <li> ادخل علي قائمة Portal وارسل لنا الماك والباسورد لرفع ملف القنوات </li>
                      <li> لتفعيل البرنامج بسعر رمزي تواصل مع الدعم الفني</li>
                    </ul>
                  </div>
                  <div className="col-md-6 year">
                    <img className='w-100' src={sumsung} alt="اشتراك شاشة سامسونج" />
                  </div>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}
