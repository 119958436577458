import React, { useEffect, useState } from 'react'
import "./AllData.css"


export default function TvShow() {

  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.mrmazika.com/s50.php');
        console.log(response);
        const data = await response.json();
        setSeriesData(data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);



  return (
    <div>
      <div className='row mt-2 justify-content-center'>
      {seriesData.length === 0 ?
        <div className='vh-100 d-flex justify-content-center align-items-center'>
            <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div> : seriesData.map((series , key)=>{
            return(<div key={key} className='col-8 col-md-2 col-sm-6 mt-4 ramadan rounded'>
              <div className='item'>
                <div className='box position-relative overflow-hidden'>
                  <div className='position-relative'>
                    <div className='position-absolute type'>{series.stream_type ? series.stream_type : "Tv Show"}</div>
                    <img className='rounded' src={series.cover ? series.cover : series.stream_icon}/>
                  </div>
                  <div className='d-flex align-items-center overflow-hidden'>
                    <p className='position-absolute bottom-0 text-center mb-0 p-1 fs-6'>
                    {series.plot}
                  </p>
                  </div>
                </div>
                <h5 className='mt-3 text-text-white-50'>{series.Genre} {series.name}</h5>
                <h6 className='mt-3 category_name'>فولدر {series.category_name}</h6>
              </div>
            </div>)
          })}
      </div>
    </div>
  )
}
