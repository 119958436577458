import React from 'react'
import "./Technical.css"
import supportImg from "../image/Service 24_7.gif"
import { Helmet } from 'react-helmet';



export default function TechnicalSupport() {
  return (
    <>
      <div className="Technical d-flex justify-content-center align-items-center">
      <Helmet>
          <title>الدعم | سيرفر عرب ليونز</title>
          <link rel="canonical" href="https://lionz.tv/#/TechnicalSupport" />
          <meta name="description" content="الدعم الفني لسيرفر عرب ليونز"></meta>
        </Helmet>
        <div className="container-fluid">
          <div
            className="heder-support text-center"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            <h2> الأسئلة الشائعة حول سيرفر عرب ليونز</h2>
            <p className="text-white-50">
              في حال وجود استفسار يتعلق بالخدمه او الاشتراك لا تتردد في الاتصال
              بنا
            </p>
          </div>
          <div className="row align-items-center bg-content-right overflow-hidden">
            <div className="col-sm-6 overflow-y-hidden pb-5">
              <div className="content m-auto ">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="300" >
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" >
                        - هل يمكن تشغيل جهازين فى نفس الوقت ؟
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
                      <div className="accordion-body">
                        <strong>عدد الاجهزة الاقصى فى الوقت نفسه هو 1</strong>{" "}
                        <br />
                        تقدر تسجل دخول على اكثر من جهاز ولكن فقط جهاز واحد يكون
                        شغال فى الوقت نفسه
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="400" >
                    <h2 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" >
                        - ما هى سرعة النت المناسبة ؟
                      </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" >
                      <div className="accordion-body">
                        <strong>سرعة النت المطلوبة :</strong>
                        <br />
                        - 5 ميجا لتشغيل جودة SD
                        <br />
                        - 10 ميجا لتشغل جودة HD
                        <br />
                        - 20 ميجا لتشغل جودة FHD
                        <br />- 40 ميجا لتشغل جودة 4K
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="500" >
                    <h2 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
                        - مشكلة فى تسجيل الدخول للبرنامج ؟
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          حل 1 = اغلاق الجهاز والانترنت لمدة دقيقتين من الكهربا
                          <br />
                          حل 2 = حذف الاشتراك من التطبيق واضافته مرة اخري
                          <br />
                          تأكد ان الاشتراك مفعل لـ كل الدول وليس داخل مصر فقط
                          <br />
                          اذا استمرت المشكلة تواصل مع الدعم الفني
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="600" >
                    <h2 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" >
                        - كيف يمكنني تشغيل سيرفر ليونز علي شاشة ذكية؟
                      </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample" >
                      <div className="accordion-body">
                        <p>
                          يوجد الكثير من البرامج التي يعمل عليها سيرفر ليونز علي
                          تطبيقنا Lionz Tv علي الشاشات الزكية مثل سامسونج و LG و
                          انظمة الاندرويد
                          <br />
                          ويوجد تطبيقات ثانية مثل
                        </p>
                        <ul>
                          <li>- LionzاTV</li>
                          <li>- IPTV Smarters</li>
                          <li>- Room iptv</li>
                          <li>- Ibo player</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="700" >
                    <h2 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive" >
                        - علي أي أجهزه يعمل إشتراك ليونز؟
                      </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample" >
                      <div className="accordion-body">
                        <p>
                          يعمل إشتراك ليونز علي كل الأجهزه, من شاشات وهواتف ذكية
                          وأيضًا علي اللابتوب وأجهزه البلايستيشن.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item" data-aos="fade-left" data-aos-duration="800" data-aos-delay="800" >
                    <h2 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix" >
                        - مشكله التقطيع
                      </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample" >
                      <div className="accordion-body">
                        <p>التنقل بيه مصادر المختلفه</p>
                      </div>
                    </div>
                  </div>
                </div>
                <a className="mt-4 d-block btn-support" href="https://qrco.de/lionztv" target="_blank" >
                  <button className="btn ms-5 fs-5" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200" >
                    الدعم
                  </button>
                </a>
              </div>
            </div>
            <div className="col-sm-6 bg-support text-center" data-aos="fade-right" data-aos-duration="800" data-aos-delay="500" >
              <img src={supportImg} alt="دعم سيرفر عرب ليونز" />
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
